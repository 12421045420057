import React, { Fragment, useContext, useRef, useState } from 'react';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as DotsIcon } from '../../../assets/images/dots.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import CustomTooltip from '../../tooltip-new';
import { Tooltip } from '../../tooltip/tooltip';

const Menu = ({
  menuList = [],
  menuClassName = '',
  Icon = DotsIcon,
  ExtraOptions = null,
  tooltipContent = null,
  isDisabled = false,
  positionStrategy = 'fixed',
}) => {
  const menuRef = useRef(uuid());

  const { checkPermission } = useContext(OrganisationContext);

  const [showMenu, setShowMenu] = useState(false);

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowMenu(false);
    }
  };

  const onMenuItemClick = menuItem => {
    const { onClick, permission } = menuItem;
    checkPermission(() => {
      onClick && onClick();
    }, permission);
  };

  return !(menuList || []).length && !ExtraOptions ? (
    <></>
  ) : (
    <MenuWrapper
      className={`relative ${menuClassName}`}
      isDisabled={isDisabled}
      onBlur={onBlur}
      tabIndex={0}
      onClick={e => {
        e.stopPropagation();
        setShowMenu(!showMenu);
      }}>
      <CustomTooltip
        id={`menu-selector-${menuRef.current}`}
        clickable
        noArrow
        isOpen={showMenu && (menuList.length > 0 || ExtraOptions)}
        place="left-start"
        positionStrategy={positionStrategy}
        content={
          <div className="menu-wrapper w-auto">
            {menuList.map(menuItem => (
              <Fragment key={menuItem.id}>
                {menuItem.topSeparator && <hr className="hr-line" />}
                <div key={menuItem.name} className="cursor menu-item" onClick={() => onMenuItemClick(menuItem)}>
                  {menuItem.withIcon && <menuItem.icon height={20} width={20} className="mr-2" />}
                  <span className="regular-text">{menuItem.name}</span>
                </div>
              </Fragment>
            ))}
            {ExtraOptions || <></>}
          </div>
        }>
        <Tooltip
          content={<span className="regular-text font-12">{tooltipContent}</span>}
          shouldOpen={tooltipContent && !showMenu && !isDisabled}
          placement="bottom">
          <div className={`flex items-center justify-center cursor menu-btn ${showMenu && 'selected-menu-btn'}`}>
            <Icon className={`menu-icon ${showMenu ? 'selected-menu-icon' : ''}`} />
          </div>
        </Tooltip>
      </CustomTooltip>
    </MenuWrapper>
  );
};

const MenuWrapper = styled('div')`
  position: relative;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.3' : '1')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};

  .menu-btn {
    height: 32px;
    width: 32px;
    border-radius: 12px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.backgroundColor};
      // border: 1px solid ${({ theme }) => theme.colors.ZenDefaultSecondary};
    }
    .selected-menu-icon {
      fill: ${({ theme }) => theme.colors.zenPurple} !important;
      color: ${({ theme }) => theme.colors.zenPurple};
    }
  }
  .selected-menu-btn {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }

  .menu-wrapper {
    width: auto;
    min-width: 160px;
    padding: 8px;
    z-index: 10;
    background: #ffffff;
    border-radius: 12px;

    .hr-line {
      margin: 8px -8px;
      width: calc(100% + 16px);
      background: ${({ theme }) => theme.colors.ZenGray2};
    }

    .menu-item {
      padding: 8px;
      border-radius: 8px;
      &:hover {
        background-color: ${({ theme }) => theme.colors.backgroundColor};

        span {
          color: ${({ theme }) => theme.colors.zenPurple};
        }
      }
    }
  }

  .tooltip-content {
    padding: 3px 7px;
    box-shadow: 0px 4px 10px rgba(5, 49, 73, 0.08);
    z-index: 5;
  }
`;

export default Menu;
