import styled from 'styled-components';

export const UsersListWrapper = styled.div`
  overflow: scroll;
`;

export const UsersListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 230px) minmax(300px, 350px) minmax(200px, 1fr) minmax(150px, 200px);
  min-height: 80px;
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
  padding: 8px 16px;

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  }

  .bdr-right {
    border-right: 1px solid ${({ theme }) => theme.colors.divider};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.hoverColor};
  }

  .icon-wrapper {
    padding: 6px 8px;
  }

  .teams-wrapper {
    background: ${({ theme }) => theme.colors.backgroundColor};
    padding: 6px 8px;
  }

  .status-container {
    width: fit-content;
    border-radius: 18px;

    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
    }
  }

  .active {
    background: ${({ theme }) => theme.colors.ZenPositiveSecondary};
    span {
      color: ${({ theme }) => theme.colors.ZenPositiveText};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenPositive};
    }
  }

  .inactive {
    background: ${({ theme }) => theme.colors.ZenGray2};
    span {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenDefault};
    }
  }

  .pending {
    background: ${({ theme }) => theme.colors.ZenOrangeSecondary};
    span {
      color: ${({ theme }) => theme.colors.ZenOrange};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenOrange};
    }
  }

  .expired {
    background: ${({ theme }) => theme.colors.ZenRedSecondary};
    span {
      color: ${({ theme }) => theme.colors.ZenRed};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenRed};
    }
  }

  .invited {
    background: ${({ theme }) => theme.colors.customStatusBackground};
    span {
      color: ${({ theme }) => theme.colors.customStatusText};
    }
    .dot {
      background: ${({ theme }) => theme.colors.customStatusText};
    }
  }
`;
