import styled from 'styled-components';

export const ChartWrapper = styled.div`
  .line-chart-over .echarts-for-react,
  .bar-column-chart .echarts-for-react {
    height: 264px !important;
  }

  @media (max-width: 1349px) {
    .line-chart-draw,
    .winner-chart {
      max-width: 50% !important;
      width: 50% !important;
    }
    .overview-respo {
      height: 100%;
      overflow-y: scroll;
    }
    .chart-respo {
      max-width: 415px !important;
    }
  }

  @media (max-width: 1267px) {
    .line-chart-draw,
    .winner-chart,
    .total-players-chart,
    .length-support-respo {
      max-width: 100% !important;
      width: 100% !important;
    }
    .chart-respo {
      max-width: 336px !important;
    }
    .total-players-chart {
      grid-column: 1 / span 2;
      max-width: 100% !important;
      width: 100% !important;
    }
    .two-chart-respo {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      gap: 16px;
    }
  }

  @media (max-width: 1024px) {
    margin: 0 20px !important;
    .responsive-two-column-grid {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      gap: 16px;
    }
    .responsive-one-column-grid {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      gap: 16px;
    }

    .total-players-chart,
    .play-total-respo {
      grid-column: 1 / span 2;
      max-width: 100% !important;
      width: 100% !important;
    }
    .chart-respo {
      max-width: 100% !important;
      width: 100% !important;
    }
    .length-support-respo,
    .project-profit-respo {
      width: 100% !important;
    }
  }

  @media (max-width: 991px) {
    .responsive-one-column-grid {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      gap: 16px;
    }
    .line-chart-draw,
    .winner-chart {
      max-width: 100% !important;
      width: 100% !important;
    }
    .length-support-respo,
    .project-profit-respo {
      width: 100% !important;
    }
  }

  @media (max-width: 771px) {
    .responsive-two-column-grid,
    .responsive-one-column-grid,
    .tablet-respo-graph {
      display: grid;
      grid-template-columns: 1fr !important;
      width: 100%;
      gap: 16px;
    }

    .line-chart-draw,
    .winner-chart {
      min-width: 100% !important;
      width: 100% !important;
    }
    .total-players-chart,
    .play-total-respo {
      grid-column: 1 / span 1;
      min-width: 100% !important;
      width: 100% !important;
    }
    .length-support-respo,
    .project-profit-respo {
      min-width: 100% !important;
      width: 100% !important;
    }
  }
`;
