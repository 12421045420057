import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import {
  getCurruntPlayers,
  getDrawProfit,
  getPlayersSupport,
  getProfitProject,
  getWinnerNumber,
} from '../../store/features/overviewSlice';
import { addToast } from '../../store/features/toastSlice';
import { ChartWrapper } from '../../styles/components/overview/overview-styled';
import LengthSupport from './LengthSupport';
import PlayersDetails from './Players';
import TotalPlayers from './TotalPlayers';
import WinnerNumber from './WinnerNumber';
import OverviewFilter from './filter';

const Overview = ({ expanded }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const userOrganization = getItemFromLocalStorage('user')?.organizations?.[0];
  const categoryTag = userOrganization?.category?.tag?.tag;

  const [filter, setFilter] = useState(null);

  const fetchOverviewData = () => {
    const { client, beneficiary } = filter || {};
    const overviewParams = filter
      ? categoryTag === 'SAAS_OPERATOR'
        ? beneficiary?.length
          ? { beneficiary_id: beneficiary.map(b => b.id).join(',') }
          : { client_id: client?.id }
        : categoryTag === 'CLIENT' && beneficiary?.id
          ? { beneficiary_id: beneficiary.id }
          : {}
      : {};
    Promise.all([
      dispatch(getCurruntPlayers({ params: overviewParams })),
      dispatch(getDrawProfit({ params: overviewParams })),
      dispatch(getWinnerNumber({ params: overviewParams })),
      dispatch(getPlayersSupport({ params: overviewParams })),
      dispatch(getProfitProject({ params: overviewParams })),
    ]).catch(e => {
      dispatch(addToast({ error: true, text: 'Error while fetching overview data', id: nanoid() }));
    });
  };

  useEffect(() => {
    fetchOverviewData();
  }, [filter?.client?.id, filter?.beneficiary]);

  return (
    <ChartWrapper className="flex flex-1 flex-column mx-10  py-6">
      <div className="flex justify-between items-center pb-8">
        <div className="font-20  medium-text reguler-text">
          {categoryTag === 'SAAS_OPERATOR'
            ? t('STAFF_ADMIN_DASHBOARD')
            : categoryTag === 'CLIENT'
              ? t('CLIENT_ADMIN_DASHBOARD')
              : categoryTag === 'BENEFICIARY'
                ? t('BANIFICIARY_DASHBOARD')
                : t('DASHBOARD')}
        </div>
        {categoryTag !== 'BENEFICIARY' && (
          <OverviewFilter categoryTag={categoryTag} filter={filter} setFilter={setFilter} />
        )}
      </div>
      <div className="overview-respo overflow-scroll h-full">
        <div className="flex flex-1 pb-6">
          <PlayersDetails />
        </div>
        <div className="flex-column row-gap-4">
          <div className={`flex wrap col-gap-4 responsive-two-column-grid  ${expanded ? 'two-chart-respo' : ''}`}>
            {/* <DrawProfit expanded={expanded} /> */}
            <WinnerNumber expanded={expanded} />
            <LengthSupport />
          </div>
          <div
            className={`flex wrap col-gap-4 ${expanded ? 'responsive-one-column-grid' : 'tablet-respo-graph gap-4'}`}>
            <TotalPlayers expanded={expanded} />
            {/* <ProjectProfit /> */}
          </div>
        </div>
      </div>
    </ChartWrapper>
  );
};
export default Overview;
