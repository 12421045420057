import styled from 'styled-components';

export const SettingsWrapper = styled.div``;

export const SecurityWrapper = styled.div`
  .content-box {
    border: 1px solid ${({ theme }) => theme.colors.ZenGray2};
  }
`;

export const UsersWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;
