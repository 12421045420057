import React from 'react';
import { BoxWrapper } from './box.styled';

const Box = ({ title, subTitle, icon, onClick = () => {} }) => {
  return (
    <BoxWrapper className="flex items-center justify-start w-full pxy-6 cursor radius-4" onClick={() => onClick()}>
      {icon && <div className="mr-6 h-full">{icon}</div>}
      <div className="flex-column items-start justify-start flex-1 cursor">
        {title && <label className="medium-text mb-1 font-16 ">{title}</label>}
        {subTitle && <label className="regular-text font-14 subtitle-text grey-text">{subTitle}</label>}
      </div>
    </BoxWrapper>
  );
};

export default Box;
