import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../../../assets/images/check.svg';
import { ReactComponent as ClosePurpleIcon } from '../../../assets/images/close-purple.svg';

const UpdateAction = ({ onUpdate = () => {}, onCancel = () => {}, className = '' }) => {
  return (
    <UpdateActionWrapper className={`flex col-gap-2 pxy-2 ${className}`}>
      <div className="flex items-center justify-center close-icon-container cursor" onClick={e => onCancel(e)}>
        <ClosePurpleIcon width={16} height={16} />
      </div>
      <div className="flex items-center justify-center check-icon-container cursor" onClick={e => onUpdate(e)}>
        <CheckIcon width={16} height={16} />
      </div>
    </UpdateActionWrapper>
  );
};

const UpdateActionWrapper = styled('div')`
  position: absolute;
  background: #ffffff;
  right: 0;
  box-shadow: 0px 4px 10px rgba(5, 49, 73, 0.12);
  border-radius: 100px;
  z-index: 10;

  .close-icon-container,
  .check-icon-container {
    padding: 6px;
    border-radius: 100px;
  }

  .close-icon-container {
    border: 2px solid ${({ theme }) => theme.colors.zenPurple};
  }

  .check-icon-container {
    background: ${({ theme }) => theme.colors.zenPurple};
    border: 2px solid ${({ theme }) => theme.colors.zenPurple};
  }
`;

export default UpdateAction;
