import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 256px;
  background: ${({ theme }) => theme.colors.sidebarBackground};
  max-height: 100vh;
  overflow-y: scroll;
  border-right: 1px solid ${({ theme }) => theme.colors.ZenGray2};

  .masquerade-details-box {
    top: 60px;
    left: 10px;
    width: calc(100% - 15px);
    z-index: 10;
  }

  .masquerade-items-wrapper {
    max-height: 140px;
  }

  .horizontal-line {
    margin: 0px;
  }

  .masquerade-organization-item {
    min-height: 32px;

    :hover {
      background: ${({ theme }) => theme.colors.backgroundColor};
      label {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
    }
  }

  .masquerade-user-icon {
    background: #ffff;
  }

  .selected-organization {
    background: ${({ theme }) => theme.colors.backgroundColor};
    label {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
  }

  &.expanded {
    width: 256px;
    min-width: 256px;

    .expand-icon {
      left: 235px;
      top: 24px;
    }
  }

  &.collapsed {
    width: 80px;
    min-width: 80px;

    .expand-icon {
      left: 56px;
      top: 50px;
    }
  }

  .zen-logo {
    padding: 24px 24px 0 24px;
  }

  .expand-icon {
    background: ${({ theme }) => theme.colors.zenPurple};
    left: 235px;
    top: 24px;
    z-index: 10;
    padding: 6px 6px;
    border-radius: 50%;
    box-shadow: 3px 0px 2px rgba(0, 0, 0, 0.19);
    border: 2px solid #ffffff;
  }

  .sidebar-top {
    display: flex;
    flex-direction: column;
    position: relative;
    outline: 0;
    flex: 1 1;
  }

  .access-account-sidebar {
    background: ${({ theme }) => theme.colors.backgroundColor};

    :hover {
      background: ${({ theme }) => theme.colors.accountAccessHover};
    }
  }

  .sidebar-bottom {
    background: ${({ theme }) => theme.colors.zenPurple};
    position: relative;

    .user-name {
      height: 62px;

      .userProfile-box {
        width: 32px;
        height: 32px;
      }

      .profile-icon {
        border-radius: 8px;
        width: 32px;
        height: 32px;
        border: 1px solid #eceef3;
      }
    }

    .show-menu {
      height: 100px;
    }

    .hidden-menu {
      height: 0;
    }

    .user-menu {
      position: absolute;
      background: ${({ theme }) => theme.colors.sidebarBackground};
      z-index: 2;
      bottom: 65px;
      left: 5px;
      right: 5px;
      overflow: hidden;
      transition: height 200ms linear 0s;
      box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.1);

      .user-action {
        border-left: 4px solid #ffffff;
        &:hover {
          background: rgba(245, 249, 252, 0.5);
          border-color: rgba(245, 249, 252, 0.5);

          .user-action-icon {
            path {
              fill: ${({ theme }) => theme.colors.zenPurple};
            }
          }
        }
      }

      .user-action-selected {
        border-left: 4px solid ${({ theme }) => theme.colors.zenPurple};
        .user-action-icon {
          path {
            fill: ${({ theme }) => theme.colors.zenPurple};
          }
        }
        &:hover {
          border-left: 4px solid ${({ theme }) => theme.colors.zenPurple};
        }
      }
    }
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      &.expanded {
        width: 220px;
        min-width: 220px;

        .expand-icon {
          left: 200px;
        }
      }
    }

    ${up(breakpoints.xl)} {
      &.expanded {
        width: 280px;
        min-width: 280px;

        .expand-icon {
          left: 265px;
        }
      }
    }
  `}
`;

export const SidebarMenuItemWrapper = styled.div`
  user-select: none;

  .menu-item-container {
    height: 50px;

    .mi-item {
      height: 50px;

      .mi-icon-stroke,
      .mi-icon {
        height: 16px;
        width: 16px;
      }

      .mi-arrow {
        transition-duration: 300ms;
        transition-property: transform;
      }

      &:hover {
        background: rgba(245, 249, 252, 0.5);
        border-color: rgba(245, 249, 252, 0.5);

        .mi-icon {
          path {
            fill: ${({ theme }) => theme.colors.zenPurple};
          }
        }

        .mi-icon-stroke {
          path {
            stroke: ${({ theme }) => theme.colors.zenPurple};
          }
        }
      }
      &:before {
        content: '';
        position: absolute;
        height: 50px;
        left: 0;
        width: 4px;
        background: #ffffff;
        border-radius: 0px 8px 8px 0px;
      }
    }

    .mi-item-selected {
      background: ${({ theme }) => theme.colors.backgroundColor};
      .mi-icon {
        path {
          fill: ${({ theme }) => theme.colors.zenPurple};
        }
      }
      .mi-name {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
      .mi-icon-stroke {
        path {
          stroke: ${({ theme }) => theme.colors.zenPurple};
        }
      }
      &:before {
        background: ${({ theme }) => theme.colors.zenPurple};
      }
      &:hover {
        background: ${({ theme }) => theme.colors.backgroundColor}
        border-color: ${({ theme }) => theme.colors.zenPurple};
      }
    }

    .mi-subitem-selected {
      background: ${({ theme }) => theme.colors.backgroundColor};
      &:before {
        background: ${({ theme }) => theme.colors.zenPurple};
      }
      &:hover {
        background: ${({ theme }) => theme.colors.backgroundColor};
        border-color: ${({ theme }) => theme.colors.zenPurple};

        .mi-icon {
          path {
            fill: ${({ theme }) => theme.colors.RegularText};
          }
        }
        .mi-icon-stroke {
          path {
            stroke: ${({ theme }) => theme.colors.RegularText};
          }
        }
      }
    }
  }

  .menu-subitems {
    overflow: hidden;
    transition: height 300ms linear 0s;
    margin-top: 2px;

    .subitem-container {
      .subitem {
        height: 50px;
      }

      &:hover {
        background: rgba(245, 249, 252, 0.5);
        border-color: rgba(245, 249, 252, 0.5);

        .mi-icon {
          path {
            fill: ${({ theme }) => theme.colors.zenPurple};
          }
        }
        .mi-icon-stroke {
          path {
            stroke: ${({ theme }) => theme.colors.zenPurple};
          }
        }
      }
    }

    .subitem-selected {
      .subitem {
        .si-name {
          color: ${({ theme }) => theme.colors.zenPurple};
        }
      }
    }
  }

  .menu-subitems-selected-open {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .menu-subitems-hide {
    height: 0;
    overflow: hidden;
    transition: height 300ms linear 0s;
  }

  .mi-arrow-down {
    transform: rotate(90deg);
  }
`;
