import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Elements/button/button';
import Menu from '../../components/common/menu';
import UpdateAction from '../../components/common/update-action';

const TemplateItem = ({
  template = {},
  editTemplate = {},
  handleTemplateChanges = () => {},
  setEditTemplate = () => {},
  handleUpdateTemplate = () => {},
  handleDeleteTemplate = () => {},
  handleSelectedTemplate = () => {},
  selectedTemplate = {},
  showSaveChangesBtn = false,
  handleSelectedTemplateUpdate = () => {},
}) => {
  const { t } = useTranslation();
  const normalUI = (
    <div
      className={`flex-column radius-4 px-4 py-3 template-item-wrapper cursor row-gap-2 ${
        selectedTemplate?.id === template.id ? 'selected-template' : ''
      }`}
      onClick={() => handleSelectedTemplate(template)}>
      <div className="flex items-center w-full">
        <div className="flex-column row-gap-1 flex-1">
          <label className="medium-text regular-text font-16">{template?.name}</label>
          <label className="regular-text lighter-text font-16">{template?.description}</label>
        </div>
        <Menu
          menuList={[
            { name: t('EDIT'), onClick: () => setEditTemplate(template) },
            { name: t('REMOVE'), onClick: () => handleDeleteTemplate(template) },
          ]}
        />
      </div>
      {selectedTemplate?.id === template.id && showSaveChangesBtn && (
        <div className="flex items-center w-50">
          <Button
            label="Save changes"
            borderRadius="12px"
            className="primary"
            size="medium"
            onClick={() => handleSelectedTemplateUpdate()}
            width="100%"
          />
        </div>
      )}
    </div>
  );
  const editUI = (
    <div className="flex-column relative row-gap-2 pxy-2 edit-template-wrapper radius-4">
      <input
        className="input w-full edit-input"
        value={editTemplate?.name}
        onChange={({ target }) => handleTemplateChanges('name', target.value)}
        placeholder="Name"
        autoFocus
      />
      <input
        className="input w-full edit-input"
        value={editTemplate?.description}
        onChange={({ target }) => handleTemplateChanges('description', target.value)}
        placeholder="Description"
        autoFocus
      />
      <UpdateAction
        className="update-btns"
        onCancel={() => setEditTemplate({})}
        onUpdate={() => handleUpdateTemplate()}
      />
    </div>
  );
  return editTemplate?.id === template?.id ? editUI : normalUI;
};

export default TemplateItem;
