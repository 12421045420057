import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as DecreaseLine } from '../../assets/images/decrease-line.svg';
import { ReactComponent as GrowthLine } from '../../assets/images/growth-line.svg';
import { ReactComponent as LineArrowTop } from '../../assets/images/line-arrow-top.svg';

const PlayersDetails = () => {
  const { currentOverviewStats } = useSelector(state => state?.overview);

  const { t } = useTranslation();

  const { current, previous, future_profit } = currentOverviewStats ?? {};
  const {
    players: currentPlayers,
    entries: currentEntries,
    average_entries_per_player: currentAverageEntry,
  } = current ?? {};
  const {
    players: previousPlayers,
    entries: previousEntries,
    average_entries_per_player: previousAverageEntry,
  } = previous ?? {};

  const changeInPlayers = (currentPlayers ?? 0) - (previousPlayers ?? 0);
  const changeInEntries = (currentEntries ?? 0) - (previousEntries ?? 0);
  const changeInEntriesPerPlayer = (currentAverageEntry ?? 0) - (previousAverageEntry ?? 0);

  const renderChangeInDetails = useCallback((changeIn, type) => {
    return (
      <div className="flex items-center justify-between">
        <p
          className={`font-24 semibold-text ${
            changeIn === 0 ? 'yellow-text' : changeIn > 0 ? 'green-positive-text' : 'red-text'
          }`}>
          {Math.abs(changeIn)} <span className="font-14 regular-text zen-regular-text responsive-text">{type}</span>
        </p>
        {changeIn === 0 ? (
          <LineArrowTop width={32} height={32} />
        ) : changeIn > 0 ? (
          <GrowthLine width={32} height={32} />
        ) : (
          <DecreaseLine width={32} height={32} />
        )}
      </div>
    );
  }, []);

  return (
    <PlayersWrapper className="w-full row-gap-4 flex flex-column">
      <div
        className="flex-row col-gap-4 w-full responsive-two-column-grid"
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', rowGap: '16px' }}>
        <div className="radius-3 bg-backgroundClr px-4  py-4 sub-box-currunt" style={{ height: '88px' }}>
          <div className="flex items-center justify-between pb-1_5 ">
            <label className="flex gap-1 medium-text font-14 lighter-text text-uppercase responsive-text">
              {t('CURRENT_PLAYERS')}
            </label>
          </div>
          <p className="font-24 semibold-text zen-regular-text">
            {currentPlayers}{' '}
            <span className="font-14 regular-text zen-regular-text responsive-text">{t('ACTIVE_PLAYERS')}</span>
          </p>
        </div>
        <div className="radius-3 bg-backgroundClr px-4  py-4 sub-box-currunt" style={{ height: '88px' }}>
          <div className="flex items-center justify-between pb-1_5 ">
            <label className="flex gap-1 medium-text font-14 lighter-text text-uppercase responsive-text">
              {t('CHANGE_IN_PLAYERS')}
            </label>
            <label className="flex gap-1 regular-text font-12 lighter-text total-week-respo"> {t('LAST_6_WEEK')}</label>
          </div>
          {renderChangeInDetails(changeInPlayers, t('CHANGE_IN_PLAYERS'))}
        </div>

        <div className="radius-3 bg-backgroundClr px-4  py-4 sub-box-currunt" style={{ height: '88px' }}>
          <div className="flex items-center justify-between pb-1_5 ">
            <label className="flex gap-1 medium-text font-14 lighter-text text-uppercase responsive-text">
              {t('CURRENT_ENTRIES')}
            </label>
          </div>
          <p className="font-24 semibold-text zen-regular-text">
            {currentEntries}{' '}
            <span className="font-14 regular-text zen-regular-text responsive-text">{t('ENTRIES')}</span>
          </p>
        </div>
      </div>
      <div
        className="col-gap-4 w-full responsive-two-column-grid"
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: '16px' }}>
        <div className="radius-3 bg-backgroundClr px-4  py-4 sub-box-currunt" style={{ height: '88px' }}>
          <div className="flex items-center justify-between pb-1_5 ">
            <label className="flex gap-1 medium-text font-14 lighter-text text-uppercase responsive-text">
              {t('CHANGE_ENTRIES')}
            </label>
            <label className="flex gap-1 regular-text font-12 lighter-text total-week-respo">{t('LAST_6_WEEK')}</label>
          </div>
          {renderChangeInDetails(changeInEntries, t('CHANGE_ENTRIES'))}
        </div>

        <div className="radius-3 bg-backgroundClr px-4  py-4 sub-box-currunt" style={{ height: '88px' }}>
          <div className="flex items-center justify-between pb-1_5 ">
            <label className="flex gap-1 medium-text font-14 lighter-text text-uppercase responsive-text">
              {t('AVERAGE_ENTRIES_PER_PLAYERS')}
            </label>
          </div>
          <p className="font-24 semibold-text zen-regular-text">
            {currentAverageEntry}{' '}
            <span className="font-14 regular-text zen-regular-text responsive-text">{t('ENTRY_PER_PLAYER')}</span>
          </p>
        </div>
      </div>
    </PlayersWrapper>
  );
};

export const PlayersWrapper = styled.div`
  @media (max-width: 1200px) {
    .responsive-text {
      font-size: 12px;
    }
    .total-week-respo {
      font-size: 10px;
    }
  }

  @media (max-width: 1024px) {
    .responsive-two-column-grid {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
    }
    .players-box-respo {
      flex-direction: row !important;
      width: 100%;
      gap: 16px;
    }
    .sub-box-currunt {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .players-box-respo {
      flex-direction: column !important;
    }
  }
`;

export default PlayersDetails;
